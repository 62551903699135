import * as React from "react";

import '../styles/components/_linkinbio.scss';

const LIBList = (props) => (
	<ul className="LIBList">
		{props.children}
	</ul>
);

const LIBListItem = (props) => (
	<li className="LIBList__LIBListItem">
		{props.children}
	</li>
);

const LIBHeadline = (props) => (
	<span className="LIBList__LIBListItem__LIBHeadline">
		{props.children}
	</span>
);

const LIBLink = (props) => (
	<span className="LIBList__LIBListItem__LIBLink">
		{props.children}
	</span>
);

const LIBDescription = (props) => (
	<span className="LIBList__LIBListItem__LIBDescription">
		{props.children}
	</span>
);

export { LIBList, LIBListItem, LIBHeadline, LIBLink, LIBDescription };